import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DynamicViewRoutingModule } from './dynamic-view-routing.module';
import { DynamicViewComponent } from './dynamic-view.component';
import { BlockBuilderModule } from '../../features/block-builder/block-builder.module';
import { ContentfulHelpersModule } from '../../core/contentful-helpers/contentful-helpers.module';
import { NavigationModule } from '../../features/navigation/navigation.module';
import { FooterModule } from '../../features/footer/footer.module';
@NgModule({
  declarations: [DynamicViewComponent],
  imports: [
    CommonModule,
    DynamicViewRoutingModule,
    BlockBuilderModule,
    ContentfulHelpersModule,
    NavigationModule,
    FooterModule,
  ],
})
export class DynamicViewModule {}
