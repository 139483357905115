import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DynamicViewComponent } from './dynamic-view.component';

const routes: Routes = [{ path: '', component: DynamicViewComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DynamicViewRoutingModule { }
