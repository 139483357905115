export const contextFunctions: {
  [key: string]: (value: any, match: string) => any;
} = {
  join: (variableValue: any[], match: string): string => {
    if (Array.isArray(variableValue)) {
      const seperator = match.replace(/{|}|.+\|\sjoin|\("|"\)/g, '');
      return variableValue.join(seperator);
    }
    return variableValue;
  },
};
