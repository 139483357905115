import { Component, ElementRef, OnInit } from '@angular/core';
import { ContentfulService } from '../../core/contentful.service';
import _get from 'lodash.get';
import {
  commonEnvironment,
  getDefaultLocale,
  getCurrentLocale,
  getCurrentPageType,
} from '../../../environments/environment.common';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '../../core/page.service';
import { MetaService } from '../../core/meta.service';
import { scrollToElement } from '../../features/dynamic-link/dynamic-link.component';
import { contextFunctions } from './context-functions';

@Component({
  selector: 'app-dynamic-view',
  templateUrl: './dynamic-view.component.html',
  styleUrls: ['./dynamic-view.component.scss'],
})
export class DynamicViewComponent implements OnInit {
  public page: Promise<PageFields>;

  constructor(
    private contentful: ContentfulService,
    private pageService: PageService,
    private element: ElementRef<HTMLElement>,
    private router: Router,
    private route: ActivatedRoute,
    private meta: MetaService,
  ) {}

  async ngOnInit(): Promise<void> {
    const pageAttributes = this.getPageAttributes(this.router.url);
    if (pageAttributes.pageType.template && pageAttributes.contextSlug) {
      this.page = this.getTemplatedPage(pageAttributes);
    } else {
      this.page = this.getPage(pageAttributes);
    }
    const page = await this.page;
    this.pageService.setPage(page);
    if (page) {
      this.meta.updateMetaTags(page);
      if (page.classNames) {
        this.element.nativeElement.classList.add(...page.classNames);
      }
    }
    this.scrollToAnchor();
  }

  private scrollToAnchor(): void {
    const anchor = this.route.snapshot.fragment;
    if (anchor) {
      setTimeout(() => {
        scrollToElement(anchor, 'auto');
      }, 600);
    }
  }

  private getPage({ pageSlug }: PageAttributes): Promise<PageFields> {
    return this.contentful.getPage(pageSlug).then((entry) => {
      if (!entry) {
        this.router.navigate(['/']);
        return null;
      }
      const fields = entry.fields;
      return fields;
    });
  }

  private async getTemplatedPage(
    pageAttributes: PageAttributes,
  ): Promise<PageFields> {
    const template = await this.contentful.getTemplate(
      pageAttributes.pageType.relatedTypeId,
    );

    const context = await this.contentful.getContext(
      pageAttributes.pageType.relatedTypeId,
      pageAttributes.contextSlug,
    );

    if (!template || !context) {
      // Try to get as a regular page
      return await this.getPage(pageAttributes);
    }
    const page: PageFields = this.parseWithContext(
      template.fields,
      context.fields,
    ) as PageFields;

    // Ensure hideInSearchEngines is propagated to the page fields. For entries that
    // use a template (e.g. articles) this would otherwise be overridden
    const hideInSearchEngines =
      page.hideInSearchEngines || !!context?.fields?.hideInSearchEngines;

    return {
      ...page,
      hideInSearchEngines,
      slug: page.slug || pageAttributes.cleanUrl,
    };
  }

  private getPageAttributes(url: string): PageAttributes {
    const errors = [];
    const cleanUrl = this.cleanupUrl(url);
    const currentLocale = getCurrentLocale(cleanUrl);
    if (!currentLocale) {
      errors.push('INVALID_LOCALE');
    }
    const locale = currentLocale || getDefaultLocale();
    const isHome = this.checkHome(cleanUrl, locale);
    const actualSlug = this.parseActualSlug(cleanUrl, locale);
    const pageType = getCurrentPageType(actualSlug);
    const contextSlug = this.parseContextSlug(actualSlug, pageType);

    return {
      url,
      cleanUrl,
      locale,
      actualSlug,
      pageSlug: isHome ? 'home' : actualSlug,
      pageType,
      contextSlug,
      errors,
    };
  }

  private cleanupUrl(url: string): string {
    return url
      .replace(/(\?|#).+/, '')
      .split('/')
      .filter((i) => !!i)
      .join('/');
  }

  private checkHome(cleanUrl: string, locale: Locale) {
    if (commonEnvironment.localized && locale) {
      return (
        !cleanUrl ||
        cleanUrl === locale.slug ||
        cleanUrl === '/' + locale.slug ||
        cleanUrl === locale.slug + '.json' ||
        cleanUrl === '/' + locale.slug + '.json'
      );
    }
    return !cleanUrl || cleanUrl === '/' || cleanUrl.includes('index.json');
  }

  private parseActualSlug(cleanUrl: string, locale: Locale) {
    if (locale) {
      const actualSlug = cleanUrl.replace(RegExp(`^\/?${locale.slug}\/`), '');

      return actualSlug;
    }
    return cleanUrl;
  }

  private parseContextSlug(actualSlug: string, pageType: PageType) {
    if (pageType && pageType.template) {
      const contextSlug = actualSlug.replace(
        RegExp(`\/?${pageType.slug}\/?`),
        '',
      );
      return contextSlug;
    }
    return actualSlug;
  }

  private parseWithContext = (
    object: AppObject | AppObject[] | any,
    context: AppObject,
  ): TemplateFields | Block | AppObject => {
    if (Array.isArray(object)) {
      return object.map((obj: AppObject) =>
        this.parseWithContext(obj, context),
      );
    }
    if (typeof object === 'object') {
      return Object.keys(object).reduce((obj, key) => {
        const value = object[key];
        // Ignore sys
        if (key === 'sys') {
          obj[key] = value;
          return obj;
        }

        // Value is array
        if (Array.isArray(value)) {
          obj[key] = value.reduce((values: any[], item) => {
            // is value is not an object
            if (typeof item !== 'object') {
              return [...values, this.transformWithContext(item, context)];
            }
            // if item is an array, parse each object inside
            if (Array.isArray(item)) {
              const parsed = item.map((obj_2: AppObject) =>
                this.parseWithContext(obj_2, context),
              );
              return [...values, ...parsed];
            }
            try {
              // Check if is Context Block
              const isContextBlock =
                item.sys.contentType.sys.id === 'contextBlock';
              if (isContextBlock && context && context.blocks) {
                const parsedBlocks = context.blocks.map((block: Block) =>
                  this.parseWithContext(block, context),
                );
                return [...values, ...parsedBlocks];
              }
              // Check if is Dynamic Block
              const isDynamicBlock =
                item.sys.contentType.sys.id === 'dynamicBlock';

              if (isDynamicBlock) {
                const dynamicBlock = {
                  ...item,
                  fields: {
                    ...item.fields.values,
                  },
                };
                const parsedDynamicBlock = this.parseWithContext(
                  dynamicBlock,
                  context,
                );
                return [...values, parsedDynamicBlock];
              }
              const parsed = this.parseWithContext(item, context);
              return [...values, parsed];
            } catch (err) {
              const parsed = this.parseWithContext(item, context);
              return [...values, parsed];
            }
          }, []);
          return obj;
        }

        // Value is object but not array
        if (typeof value === 'object') {
          obj[key] = this.parseWithContext(value, context);
          return obj;
        }

        // Default
        obj[key] = this.transformWithContext(value, context);
        return obj;
      }, {} as TemplateFields);
    }
    // Object was something else
    this.transformWithContext(object, context);
    return object;
  };

  private transformWithContext(value: any, context: AppObject) {
    // Value is string and can be transformed if has 2x brackets on both sides
    if (typeof value === 'string' && RegExp(/{{[^{}]+}}/g).test(value)) {
      // Three dots gets full context
      if (value === '{{...}}') {
        return context;
      }
      // Transform all matches
      const transformed = value.match(/{{[^{}]+}}/g).reduce((acc, match) => {
        // Custon functions
        if (match.includes('|')) {
          try {
            const [functionKey] = Object.keys(contextFunctions).filter(
              (key) => {
                return new RegExp(`.+\\|\\s${key}.+`, 'g').test(match);
              },
            );
            if (functionKey) {
              const variableRegExp = new RegExp(
                `{|}|\\s\\|\\s${functionKey}.*`,
                'g',
              );
              const functionVariableString = match.replace(variableRegExp, '');
              const functionVariableValue =
                _get(context, functionVariableString) || functionVariableString;

              const functionReturnValue = contextFunctions[functionKey](
                functionVariableValue,
                match,
              );
              if (typeof functionReturnValue === 'object') {
                return functionReturnValue;
              }
              return acc.replace(match, functionReturnValue);
            }
          } catch (error) {
            console.error(error);
            return '';
          }
        }
        const variableString = match.replace(/{|}/g, '');
        const variableValue = _get(context, variableString) || variableString;
        if (typeof variableValue === 'object') {
          return variableValue;
        }
        const replaced = acc.replace(match, variableValue);
        return replaced;
      }, value);
      return transformed;
    }
    return value;
  }
}
